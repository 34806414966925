<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mb-16 w-full">
      <KPI label="Total creator pages" :value="creatorPageCount" title="Amount of streamers with a creator page" />

      <KPI label="Total creator page visitors" :value="creatorPageVisits" title="Amount of visits to creator pages" />
    </div>
  </div>
</template>

<script>
import { KPI } from "cavea-design-system";
import axios from "axios";

export default {
  name: "AdminCreatorPages",

  metaInfo: {
    title: "Creator Page Statistics",
  },

  components: {
    KPI,
  },

  data() {
    return {
      creatorPageCount: 0,
      creatorPageVisits: 0,
    };
  },

  async created() {
    this.fetchCreatorPageCount();
    this.fetchCreatorPageVisits();
  },

  methods: {
    async fetchCreatorPageCount() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/creator-pages/total`)
        .then((res) => {
          this.creatorPageCount = res?.data?.total ?? 0;
        })
        .catch((error) => {
          console.error("fetchCreatorPageCount error", error);
        });
    },

    async fetchCreatorPageVisits() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/creator-pages/visitors/total`)
        .then((res) => {
          this.creatorPageVisits = res?.data?.visitors ?? 0;
        })
        .catch((error) => {
          console.error("fetchCreatorPageCount error", error);
        });
    },
  },
};
</script>
